import {get,post,put,put2,$delete} from '@/plugins/axios.js'

export default{
	
	async post(params) {
		return await post('/api/project-effective', params);
	},
	async del(params) {
		return await $delete('/api/project-effective', params);
	},
	async put(params) {
		return await put('/api/project-effective', params);
	},
	async get(params) {
		return await get('/api/project-effective', params);
	},
	async list(params) {
		return await get('/api/project-effective/list', params);
	},
	async retrieve(params) {
		return await get('/api/project-effective/retrieve', params);
	},
	async years(params) {
		return await get('/api/project-effective/years', params);
	},
	
	
}