<template>

	<div>
		<div class="block-header">
			<div class="row">
				<div class="col-lg-7 col-md-6 col-sm-12">
					<h2>有效项目信息
						<small class="text-muted"></small>
					</h2>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body" @keyup.enter="queryList(1)">
							<el-row>
								<el-col :span="12">
									<el-button-group>
										<el-button type="primary" size="mini" icon="el-icon-plus"
											@click.native="go('/project-effective-editor')">添加项目</el-button>
										<el-button v-if="role.type==='system'" type="danger" size="mini" icon="el-icon-delete" @click="del">
											删除项目
										</el-button>
									</el-button-group>
								</el-col>
							</el-row><br>
							<el-row>
								<el-col :span="12">
									<el-input placeholder="关键字检索 「项目名称 / 填报单位 / 投资方名称 / 产业类别 / 建设地点」" v-model="query_keyword" size="mini"
										class="input-with-select">
										<placeSelect v-model="query_type" placeholder="投资方所属地"  slot="prepend"></placeSelect>
										<el-button slot="append" icon="el-icon-search" @click="queryList(1)">
										</el-button>
									</el-input>

								</el-col>
								<el-col :span="4" :offset="1">
									<b>年份</b>: <el-select v-model="query_year" size="mini" placeholder="请选择"
										@change="queryList(1)">
										<el-option v-for="(item,index) in query_years" :label="item" :value="item">
										</el-option>
									</el-select>
								</el-col>
							</el-row>
							<br /><br />

							<el-table ref="multipleTable" :data="list" style="width: 100%;" border 
								@selection-change="handleSelectionChange" height="580px" size="mini" >
								<el-table-column type="selection" width="40" align="center"></el-table-column>
								<el-table-column prop="项目名称" label="项目名称" align="center"></el-table-column>
								<el-table-column prop="填报单位" label="填报单位" align="center" ></el-table-column>
								<el-table-column prop="投资方名称" label="投资方名称" align="center" ></el-table-column>
								<el-table-column prop="投资方所属地" label="投资方所属地" align="center" ></el-table-column>
								<el-table-column prop="建设内容" label="建设内容" align="center" show-overflow-tooltip ></el-table-column>
								<el-table-column prop="产业类别" label="产业类别" align="center"></el-table-column>
								<el-table-column prop="总投资额" label="总投资额(万元)" align="center" ></el-table-column>
								<el-table-column prop="用地面积" label="用地面积" align="center" ></el-table-column>
								<el-table-column prop="建设地点" label="建设地点" align="center" ></el-table-column>
								<el-table-column prop="填报时间" label="填报时间" align="center"></el-table-column>
								<el-table-column label="操作" width="70" align="center">
									<template v-slot="scope">
										<el-button @click="go(`/project-effective-editor/${scope.row.unique}`)"
											icon="el-icon-edit" type="text" size="small">编辑</el-button>
									</template>
								</el-table-column>
							</el-table>
							<el-pagination style="margin-top: 40px;" @size-change="handleSizeChange"
								@current-change="queryList" :current-page="page.currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="page.pageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="page.count"></el-pagination>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import placeSelect from '../components/PlaceSelect.vue' 
	import projectEffectiveController from "../controller/projectEffectiveController.js"
	export default {
		components:{
			placeSelect
		},
		data() {
			return {
				multipleSelection: [],
				list: [],
				query_type: '',
				query_keyword: '',
				query_year: '',
				query_years: [],
				page: {
					pageSize: 10,
					currentPage: 1,
				},
				role: {}

			}
		},
		created() {
			this.role = JSON.parse(sessionStorage.getItem('role'))
			this.initQueryYears();
		},
		methods: {
			queryList(currentPage) {

				this.page.currentPage = currentPage ? currentPage : 1;
				projectEffectiveController.list({
					query_type: this.query_type,
					query_keyword: this.query_keyword,
					query_year: this.query_year,
					current_page: this.page.currentPage,
					page_size: this.page.pageSize
				}).then(res => {
					this.list = res.data.list;
					this.page = res.data.page;
				})

			},
			del() {
				if (this.multipleSelection.length > 0) {

					this.$confirm('此操作将永久删除勾选数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let array = [];
						for (let i = 0; i < this.multipleSelection.length; i++)
							array.push(this.multipleSelection[i].unique)
						projectEffectiveController.del({
							uniques: array.toString()
						}).then(res => this.queryList(1))
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						})
					});

				} else {
					this.$message.error('请至少勾选一条数据')
				}
			},
			handleSizeChange(val) {
				this.page.pageSize = val;
			},
			initQueryYears() {
				projectEffectiveController.years({}).then(res => {
					this.query_years = res.data
					this.query_year = res.data[0]
					this.queryList(1)
				})
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		}

	}
</script>

<style scoped>
	.el-select {
		width: 130px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}
</style>
